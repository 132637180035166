.ql-size-huge {
    font-size: 32px;
    font-weight: bold;
}

.ql-size-large {
    font-size: 18px;
    font-weight: bold;
}

.ql-size-normal {
    font-size: 16px;
}

.ql-size-small {
    font-size: 12px;
}
